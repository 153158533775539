import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Container, Typography } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  containerPart: {
    [theme.breakpoints.down("md")]: {
      marginTop: "6em",
    },
    marginTop: "10em",
  },
  table: {
    minWidth: 700,
  },
  media: {
    width: "100%",
  },
  colorGreen: {
    color: "#00e676",
    fontWeight: 900,
  },
}))

export default function Index() {
  const classes = useStyles()
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          CFL | Consulte los costes y comisiones de nuestros productos
        </title>
      </Helmet>
      <Container className={classes.containerPart}>
        <Box
          mt={4}
          py={2}
          height="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Typography variant="h1" component="h1" gutterBottom>
              Página en mantenimiento
            </Typography>

            <Typography gutterBottom>
              Actualmente estamos realizando mantenimiento en esta sección.{" "}
              <br />
              Pedimos disculpas por cualquier inconveniente que esto pueda
              causar.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}
